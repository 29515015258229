/* WAVE SPINNER */
.sk-wave {
  width: var(--sk-size);
  height: var(--sk-size);
  display: flex;
  justify-content: space-between;
}

.sk-wave-rect {
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) {
  animation-delay: -1.2s;
}
.sk-wave-rect:nth-child(2) {
  animation-delay: -1.1s;
}
.sk-wave-rect:nth-child(3) {
  animation-delay: -1s;
}
.sk-wave-rect:nth-child(4) {
  animation-delay: -0.9s;
}
.sk-wave-rect:nth-child(5) {
  animation-delay: -0.8s;
}

@keyframes sk-wave {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
/* END WAVE SPINNER */

.loader-logo {
  margin-top: 80px;
  text-align: center;
}

.loader-tagline {
  margin-top: 50px;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

// SPINNER
.plan-spinner {
  margin: 50px auto;
  width: 100px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.plan-spinner.plan-spinner-miequipo > div {
  background-color: #77b829 !important;
}

.plan-spinner > div {
  background-color: #00abc9;
  margin-left: 3px;
  height: 100%;
  width: 8px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.plan-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.plan-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.plan-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.plan-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
// END SPINNER