@import "spinner";
@import "elipsisloader";

$logo-color: #00abc8;
$qubico-color: #5CC9DF;
$primary-color-miequipo: #77b829;

.btn-primary{
    background-color: $logo-color;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: $logo-color;
}

.editor-holder-miequipo .nav-pills > li.active > a, .editor-holder-miequipo .nav-pills > li.active > a:hover, .editor-holder-miequipo .nav-pills > li.active > a:focus {
    background-color: $primary-color-miequipo;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a {
    color: #fff !important;
}

.editor-holder-miequipo .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a {
    color: #fff !important;
}

a {
    color: $logo-color;
}

ol.roman {
    list-style-type: upper-roman;
}

ol.latin {
    list-style-type: lower-latin;
}

.sm {
    font-variant: small-caps;
}

.editor-holder-miequipo a{
    color: $primary-color-miequipo;
}

.grey-box {
    overflow: hidden;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    margin-top: 0;
    margin-bottom: 1px;
    background-color: #fff;
    h4, th {
        text-align: center;
        line-height: 18px;
        height: 18px;
        box-sizing: border-box;
        moz-box-sizing: border-box;
        font-size: 0.8em;
        font-weight: normal;
        font-family: "pbRegular",Helvetica,Arial,sans-serif;
        margin: 0;
        background-color: #f5f5f5;
        border-bottom: 1px solid #d8d8d8;
        text-shadow: 1px 1px 0 rgba(255,255,255,0.8);
    }    
}    

.pd30{
    padding: 30px;
}

.pt30{
    padding-top: 30px;
}

.pl30{
    padding-left: 30px;
}

.pt60{
    padding-top: 60px;
}

.mt90{
    margin-top: 90px;
}

.mt30{
    margin-top: 30px;
}

.mt20{
    margin-top: 20px;
}

.mt10{
    margin-top: 10px;
}

.mtm10{
    margin-top: -10px;
}

.mb5{
    margin-bottom: 5px;
}

.ml20{
    margin-left: 20px;
}

.pt10{
    padding-top: 10px;
}

.fs16{
    font-size: 16px;
}

.alert-license{
    background-color: $qubico-color;
    text-align: center;
    font-size: 18px;
    color: #fff;
}

.alert-license-gym{
    background-color: $primary-color-miequipo;
    text-align: center;
    font-size: 18px;
    color: #fff;
}

.btn-qubico{
    border: none;
    font-family: inherit;
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;
    font-weight: 600;
    outline: none;
    position: relative;
    background-color: #5cc9df;
    color: #fff;
    box-shadow: 0 4px rgba(0,0,0,0.07);
    border-radius: 5px;
    -webkit-transition: none;
   -moz-transition: none;
    transition: none;    
    text-decoration: none !important;
}

.btn-qubico-inverse{
    color: #5cc9df !important;
    background-color: #fff;
}

.btn-qubico-pink{
    background-color: #f19da7 !important;
}

.btn-qubico:hover, .btn-qubico:focus {
    color: #fff;
    box-shadow: 0 3px rgba(0,0,0,0.07);
    top: 1px;
    text-decoration: none;
}

#license-options, #bono-options{
    font-size: 24px;
    padding: 50px;
    .license-detail{
        margin-right: 50px;
        font-size: 16px;
    }
    .license-name{
        white-space: nowrap;
    }
}

.white-container{
    background-color: #fff;
    margin: auto 60px;
}

.grey-container{
    padding-top: 20px;
    background-color: #f3f3f3;
}

#navbar-license{
    padding: 10px;
    color: $qubico-color;
    font-weight: bold;
}

#navbar-license-gym{
    padding: 10px;
    color: $primary-color-miequipo;
    font-weight: bold;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently
                                  not supported by any browser */
}

#main-nav li {
    margin: 0 !important;
}

.navbar-inverse .nav > li > a {
    padding: 10px 13px !important;
}

.section-title {
    position: relative;
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 38px;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-align: center;
    text-transform: uppercase;
    color: #7a7c7f;        
    font-family: Lato, Arial, sans-serif;    
    font-weight: 700;
}

.section-description {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 60px;
    font-family: Lato, Arial, sans-serif;
    font-size: 18px;
    color: #7a7c7f;    
}

.section-title:after {
    display: block;
    content: "";
    width: 100px;
    height: 4px;
    position: absolute;
    left: 50%;
    bottom: -15px;
    margin-left: -50px;
    background: #5cc9df;
    border-radius: 3px;
    box-shadow: 0 2px 0 rgba(0,0,0,0.08);
}

#login {
    min-height: 900px;
}
